import { useReactiveVar } from '@apollo/client';
import { previewUserSignKeyVar } from '@/graphql/cache';

type UserSignKeyHeadersReturnType = {
  'User-Sign-Key': string;
};

export default function useUserSignKey(): UserSignKeyHeadersReturnType | null {
  const userSignKey = useReactiveVar(previewUserSignKeyVar);

  return userSignKey ? { 'User-Sign-Key': userSignKey } : null;
}
